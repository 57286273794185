<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Crashes system version</b-card-title>

        <b-form-group style="width: 100px;">
          <v-select
            v-model="option[4]"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :clearable="false"
            :options="option"
            @input="versionSelected"
          />
        </b-form-group>


      <!-- dropdown -->
      <!-- <b-dropdown
        dropright
        variant="link"
        toggle-class="p-0"
        no-caret
      >
        <template #button-content>
          <feather-icon
            class="text-body"
            icon="MoreVerticalIcon"
          />
        </template>
        <b-dropdown-item>
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item>
          Last Month
        </b-dropdown-item>
        <b-dropdown-item>
          Last Year
        </b-dropdown-item>
      </b-dropdown> -->
      <!--/ dropdown -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
       <!-- <chartjs-component-pie-chart
        :height="600"
        :data="chartjsData.crashesPerSystemVersion.data"
        :options="chartjsData.crashesPerSystemVersion.options"
      /> -->

      <chartjs-component-doughnut-chart
      id="chartjs"
        :height="600"
        :data="chartjsData.crashesPerSystemVersion.data"
        :options="chartjsData.crashesPerSystemVersion.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardBody, BCardHeader, BDropdown, BDropdownItem, BFormGroup
} from 'bootstrap-vue'
import ChartjsComponentDoughnutChart from '@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentDoughnutChart.vue'
import chartjsData from './chartjsData'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    ChartjsComponentDoughnutChart,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BDropdown,
    BDropdownItem,
    BFormGroup
  },
  methods:{
    versionSelected(value){
      console.log("selcted " +value.title)
    }
  },
  data() {
    return {
      chartjsData,
       option: [{ title: 'V1.0' }, { title: 'V1.01' }, { title: 'V1.2' }, { title: 'V1.3' }, { title: 'V1.4' }],
    }
  },
}
</script>
