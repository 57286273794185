<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <span>Latest registred users</span>
      </div>
      <div class="m-2">
        <!--b-table striped-->
        <b-table
          class="position-relative"
           style="min-height: 300px;"
          :items="items"
          responsive
          :fields="fields"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
      

          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.full_name)"
                  :variant="status[1][data.value]"
                  :to="{
                    name: 'apps-users-view',
                    params: { id: data.item.id },
                  }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.full_name }}
              </b-link>
              <small class="text-muted">@{{ data.item.username }}</small>
            </b-media>
          </template>

          <template #cell(emailVerified)="data">
            <div v-if="data.item.emailVerified != 'no-email'">
              <b-badge
                variant="light-success"
                v-if="data.item.emailVerified == true"
              >
                Verified
              </b-badge>
              <b-badge variant="light-warning" v-else> Not verified </b-badge>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Show profile</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="showBanDialog(data.item.id, data.item.username)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Ban permanently</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Ban temporarly</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
      <div class="m-2 text-center" v-if="noMoreData == false">
        <b-button
          variant="primary"
          disabled
          class="mr-1"
          v-if="isLoading == true"
          @click="cancelLoadMore"
        >
          <b-spinner small />
          Loading...
        </b-button>
        <b-button variant="primary" class="mr-1" @click="loadMore" v-else>
          Load more
        </b-button>
      </div>
      <div class="m-2 text-center" v-else>
        <b-button variant="primary" disabled class="mr-1" @click="loadMore">
          No more data
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge, BMedia, BLink,
  BDropdown, BDropdownItem, BSpinner
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { getLatestUsers } from '@/amic-js/users'
import { getSmallThumbnail, getMediumThumbnail } from '@/amic-js/stringutils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BSpinner

  },
  methods: {
    getLatestUsers,
    getSmallThumbnail,
    getMediumThumbnail,
    loadMore() {
      this.isLoading = true;
      getLatestUsers(this.skip, this.limitPerPage).then((users) => {
        this.isLoading = false;
        this.skip = this.skip + users.length;
        if (users.length == 0) {
          this.noMoreData = true;
        }
        else {
          users.forEach(user => {
            var userImageURL = ""
            if (user.get("userImage")) {
              userImageURL = user.get("userImage").url();
              userImageURL = getMediumThumbnail(userImageURL);
            }
            var isEmailVerified;
            if (user.get("emailVerified") === undefined) {
              isEmailVerified = 'no-email'
            }
            else {
              isEmailVerified = user.get("emailVerified")
            }

            var createdDate = user.get("createdAt").toISOString().slice(0, 10)
            var createdTime = user.get("createdAt").toISOString().slice(11, (user.get("createdAt").toISOString().length)-5)

            const newUser = {
              id: user.id,
              // eslint-disable-next-line global-require
              avatar: userImageURL,
              createdAt: createdDate + "@"+createdTime,
              username: user.get("username"),
              full_name: user.get("firstName") + " " + user.get("lastName"),
              post: 'Nuclear Power Engineer',
              plan: isEmailVerified,
              email: user.get("email") ? user.get("email") : "",
              emailVerified: isEmailVerified,
              phone: user.get("phone") ? '+' + user.get("phone") : "",
              authProvider: user.get("authData") ? user.get("authData")[0] : '',
              status: 1,
            };
            this.items.push(newUser)
          });
        }
      },
        (error) => {
             this.isLoading = false;
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list ' + error.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
            {
              timeout: false,
            })
        });

    },
    cancelLoadMore() {
      this.isLoading = false;
    },
    showBanDialog(id, username) {
      this.$swal({
        title: 'Are you sure?',
        text: username + " with id " + id + " will be banned",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Permanent Ban',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: username + ' with id: ' + id + ' has been banned',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
  setup() {
    const toast = useToast();
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    return { toast, sortBy, avatarText, isSortDirDesc }
  },
  mounted() {
    this.loadMore()
  },
  data() {
    return {
      limitPerPage: 10,
      skip: 0,
      noMoreData: false,
      isLoading: false,
      //fields: ['show_details', 'id', { key: 'avatar', label: 'Avatar' }, 'full_name', 'post', 'email', 'city', 'start_date', 'salary', 'age', 'experience', { key: 'status', label: 'Status' }],
      fields: [/* { key: 'details', class: 'limied-width-column' }, */ { key: 'id', sortable: true }, { key: 'user', sortable: true }, { key: 'email', sortable: true }, 'phone', { key: 'emailVerified', label: 'Email Verified', sortable: true },'authProvider', { key:'createdAt', sortable: true}, 'plan', 'actions'],
      items: [
        /* {
          id: 1,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/10-small.png'),
          username: "korrie1",
          full_name: "Korrie O'Crevy",
          post: 'Nuclear Power Engineer',
          email: 'kocrevy0@thetimes.co.uk',
          city: 'Krasnosilka',
          start_date: '09/23/2016',
          salary: '$23896.35',
          age: '61',
          experience: '1 Year',
          status: 2,
        },
        {
          id: 2,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/1-small.png'),
          username: "korrie2",
          full_name: 'Bailie Coulman',
          post: 'VP Quality Control',
          email: 'bcoulman1@yolasite.com',
          city: 'Hinigaran',
          start_date: '05/20/2018',
          salary: '$13633.69',
          age: '63',
          experience: '3 Years',
          status: 2,
        },
        {
          id: 3,
          // eslint-disable-next-line global-require
          avatar: require('@/assets/images/avatars/9-small.png'),
          username: "korrie3",
          full_name: 'Stella Ganderton',
          post: 'Operator',
          email: 'sganderton2@tuttocitta.it',
          city: 'Golcowa',
          start_date: '03/24/2018',
          salary: '$13076.28',
          age: '66',
          experience: '6 Years',
          status: 5,
        } */
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
}
</script>
<style  >
.limied-width-column {
  max-width: 50px;
  text-align: center;   
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>