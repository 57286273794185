<template>
  <b-card>
    <b-card-header class="justify-content-center">
      <h4 class="text-center align-items-center">Parse server status</h4>
    </b-card-header>

    <div>
      <lottie-vue-player
        class="d-flex justify-content-center text-center mb-2 mx-50"
        :src="this.animation"
        :loop="false"
        :autoplay="true"
        height="245"
        style="display: block; height: 245px; background: transparent"
      />
      <!-- <b-avatar size="150" variant="light-success">
        <feather-icon size="75" icon="ActivityIcon" />
      </b-avatar> -->
    </div>

    <b-row class="text-center mx-0" style="margin-top: 20px">
      <b-col class="border-top d-flex align-items-between flex-column py-1">
        <b-card-text class="text-muted mb-0"> Status </b-card-text>
        <h3 class="text-success font-weight-bolder mb-50">Ok</h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText, BAvatar,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BAvatar,
  },
  data() {
    return {
      animation: JSON.stringify(require('@/assets/lottie/health-success.json')),
      health: {},
      goalOverviewRadialBar: {
        series: [83],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#5e5873',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
    }
  },
  created() {
    /* this.$http.get('http://localhost:1337/parse/health').then(res => {
      console.log("-> " + JSON.stringify(res))
      this.health = res.data
    }) */
  },
}
</script>
