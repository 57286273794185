<template>
  <section id="dashboard-analytics">
    <!--  <b-row  style="margin-top: 10px">
      <b-col  xl="4" md="4">
        <comment-component :comment-data="{commentId: 'EDzJjtNkaq', isAReply: false}" />
      </b-col>
    </b-row> -->
    <span class="font-weight-bold">Resume</span>
    <b-row class="match-height" style="margin-top: 10px">
  
      <b-col xl="8" md="4">
        <b-card-actions
          ref="cardAction"
          title="  "
          @refresh="refreshStop('cardAction')"
        >
          <resume-statistics :data="statisticsItems" />
        </b-card-actions>
      </b-col>
          <b-col xl="4" md="4">
        <server-status-component />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col lg="6" md="3" cols="6">
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          <!--/ Bar Chart - Orders -->
          <b-col lg="6" md="3" cols="6">
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col lg="12" md="6">
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>
    <span class="font-weight-bold">Some information about the last 7 days</span>
    <b-row class="match-height" style="margin-top: 10px">
      <!-- <b-col lg="6" md="12">
        <analytics-congratulation :data="data.congratulations" />
      </b-col> -->
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          v-if="subscribersGained"
          icon="UsersIcon"
          :statistic="kFormatter(subscribersGained.analyticsData.subscribers)"
          statistic-title="New users"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          v-if="data.ordersRecevied"
          icon="PackageIcon"
          color="warning"
          :statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"
          statistic-title="New recipes"
          :chart-data="data.ordersRecevied.series"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          v-if="data.ordersRecevied"
          icon="PackageIcon"
          color="warning"
          :statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"
          statistic-title="New Comments"
          :chart-data="data.ordersRecevied.series"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-with-area-chart
          v-if="data.ordersRecevied"
          icon="PackageIcon"
          color="warning"
          :statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"
          statistic-title="New chat messages"
          :chart-data="data.ordersRecevied.series"
        />
      </b-col>
    </b-row>

    <b-row class="match-height" style="margin-top: 10px">
      <b-col cols="12">
        <apex-activity-chart />
      </b-col>
    </b-row>

    <b-row class="match-height" style="margin-top: 10px">
      <b-col cols="8">
        <apex-crashes-per-version />
      </b-col>
      <b-col md="4">
        <chartjs-crash-system-version />
      </b-col>
    </b-row>

    <!--  <b-row class="match-height">
      <b-col lg="6">
        <analytics-avg-sessions :data="data.avgSessions" />
      </b-col>
      <b-col lg="6">
        <analytics-support-tracker :data="data.supportTracker" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <analytics-timeline :data="data.timeline" />
      </b-col>
      <b-col lg="4">
        <analytics-sales-radar-chart :data="data.salesChart" />
      </b-col>
      <b-col lg="4">
        <analytics-app-design :data="data.appDesign" />
      </b-col>
    </b-row> -->
    <span class="font-weight-bold">Latest registred users</span>
    <!--  <b-row style="margin-top: 10px">
      <b-col cols="12">
        <users-list-table />
      </b-col>
    </b-row> -->

    <b-row style="margin-top: 10px">
      <b-col cols="12">
        <users-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import UsersListTable from '@/views/amic-views/components/users/users-list/UsersListTable.vue'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
import AnalyticsTimeline from './AnalyticsTimeline.vue'
import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
import AnalyticsAppDesign from './AnalyticsAppDesign.vue'
import EcommerceProfitChart from '@/views/dashboard/ecommerce/EcommerceProfitChart.vue'
import EcommerceOrderChart from '@/views/dashboard/ecommerce/EcommerceOrderChart.vue'
import EcommerceEarningsChart from '@/views/dashboard/ecommerce/EcommerceEarningsChart.vue'
import EcommerceRevenueReport from '@/views/dashboard/ecommerce/EcommerceRevenueReport.vue'
import ResumeStatistics from '@/views/amic-views/resume/ResumeStatistics.vue'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ApexActivityChart from '@/views/amic-views/charts/ApexActivityChart.vue'
import ApexCrashesPerVersion from '@/views/amic-views/charts/ApexCrashesPerVersion.vue'
import ChartjsCrashSystemVersion from '@/views/amic-views/charts/ChartjsCrashSystemVersion.vue'

import UsersList from '@/views/amic-views/components/users/UsersList.vue'
import CommentComponent from '@/views/amic-views/components/comment/CommentComponent.vue'
import ServerStatusComponent from '@/views/amic-views/components/ServerStatusComponent.vue'

export default {
  components: {
    CommentComponent,
    BRow,
    BCol,

    // AnalyticsCongratulation,
    //AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    //AnalyticsSalesRadarChart,
    //AnalyticsAppDesign,
    EcommerceRevenueReport,
    EcommerceEarningsChart,
    EcommerceOrderChart,
    EcommerceProfitChart,
    UsersListTable,
    UsersList,

    BCardActions,
    ResumeStatistics,
    ApexActivityChart,
    ApexCrashesPerVersion,
    ChartjsCrashSystemVersion,
    ServerStatusComponent
  },
  methods: {
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'UsersIcon',
          color: 'light-info',
          title: '230k',
          subtitle: 'Users',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Recipes',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-info',
          title: '1.549k',
          subtitle: 'Hashtags',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-info',
          title: '1.423k',
          subtitle: 'Comments',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'BarChart2Icon',
          color: 'light-info',
          title: '3.23k',
          subtitle: 'Comments replies',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'MessageSquareIcon',
          color: 'light-info',
          title: '450.265k',
          subtitle: 'Chat messages',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'MessageSquareIcon',
          color: 'light-warning',
          title: '450.265k',
          subtitle: 'Contacts',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'AlertTriangleIcon',
          color: 'light-primary',
          title: '450.265k',
          subtitle: 'Crashes',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'AlertCircleIcon',
          color: 'light-warning',
          title: '450.265k',
          subtitle: 'Reported users',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'AlertCircleIcon',
          color: 'light-warning',
          title: '450.265k',
          subtitle: 'Reported recipes',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'AlertCircleIcon',
          color: 'light-warning',
          title: '450.265k',
          subtitle: 'Reported comments',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'AlertCircleIcon',
          color: 'light-warning',
          title: '450.265k',
          subtitle: 'Reported Chats',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DatabaseIcon',
          color: 'light-success',
          title: '5GB',
          subtitle: 'Database usage',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'HardDriveIcon',
          color: 'light-success',
          title: '85GB',
          subtitle: 'Storage usage',
          customClass: 'mb-2 mb-sm-0',
        },

      ],
      subscribersGained: {
        series: [
          {
            name: 'Users',
            data: [10, 15, 8, 15, 7, 12, 8],
          },
        ],
        analyticsData: {
          subscribers: 92600,
        },
      },
      data: {
        congratulations: {
          name: 'John',
          saleToday: '57.6',
        },
        ordersRecevied: {
          series: [
            {
              name: 'Orders',
              data: [10, 15, 8, 15, 7, 12, 8],
            },
          ],
          analyticsData: {
            orders: 38400,
          },
        },
        avgSessions: {
          sessions: 2700,
          lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
          growth: '+5.2%',
          goal: 100000,
          users: 100000,
          retention: 90,
          duration: 1,
          salesBar: {
            series: [
              {
                name: 'Sessions',
                data: [75, 125, 225, 175, 125, 75, 25],
              },
            ],
          },
        },
        supportTracker: {
          title: 'Support Tracker',
          lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
          totalTicket: 163,
          newTicket: 29,
          openTicket: 63,
          responseTime: 1,
          supportTrackerRadialBar: {
            series: [83],
          },
        },
        timeline: {
          step1: {
            title: '12 Invoices have been paid',
            subtitle: 'Invoices have been paid to the company.',
            img: require('@/assets/images/icons/json.png'),
            fileName: 'data.json',
            duration: '12 min ago',
          },
          step2: {
            title: 'Client Meeting',
            subtitle: 'Project meeting with john @10:15am',
            avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
            avatarName: 'John Doe (Client)',
            occupation: 'CEO of Infibeam',
            duration: '45 min ago',
          },
          step3: {
            title: 'Create a new project for client',
            subtitle: 'Add files to new design folder',
            duration: '2 day ago',
            avatars: [
              { userImg: require('@/assets/images/portrait/small/avatar-s-9.jpg'), name: 'Billy Hopkins' },
              { userImg: require('@/assets/images/portrait/small/avatar-s-6.jpg'), name: 'Amy Carson' },
              { userImg: require('@/assets/images/portrait/small/avatar-s-8.jpg'), name: 'Brandon Miles' },
              { userImg: require('@/assets/images/portrait/small/avatar-s-7.jpg'), name: 'Daisy Weber' },
              { userImg: require('@/assets/images/portrait/small/avatar-s-20.jpg'), name: 'Jenny Looper' },
            ],
          },
          step4: {
            title: 'Create a new project for client',
            duration: '5 day ago',
            subtitle: 'Add files to new design folder',
          },
        },
        salesChart: {
          series: [
            {
              name: 'Sales',
              data: [90, 50, 86, 40, 100, 20],
            },
            {
              name: 'Visit',
              data: [70, 75, 70, 76, 20, 85],
            },
          ],
        },
        appDesign: {
          date: '03 Sep, 20',
          title: 'App design',
          subtitle: 'You can Find Only Post and Quotes Related to IOS like ipad app design, iphone app design',
          teams: [
            { name: 'Figma', color: 'light-warning' },
            { name: 'Wireframe', color: 'light-primary' },
          ],
          members: [
            { img: require('@/assets/images/portrait/small/avatar-s-9.jpg'), color: 'primary' },
            { text: 'PI', color: 'light-danger' },
            { img: require('@/assets/images/portrait/small/avatar-s-14.jpg'), color: 'primary' },
            { img: require('@/assets/images/portrait/small/avatar-s-7.jpg'), color: 'primary' },
            { text: 'AL', color: 'light-secondary' },
          ],
          planing: [
            { title: 'Due Date', subtitle: '12 Apr, 21' },
            { title: 'Budget', subtitle: '$49251.91' },
            { title: 'Cost', subtitle: '$840.99' },
          ],
        },
      }
    }
  },
  created() {
    // data
    /* this.$http.get('/analytics/data')
      .then(response => { this.data = response.data }) */
  },
  methods: {
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>