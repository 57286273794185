import * as Parse from './parse'

export async function getLatestUsers(skip, limit) {
    const query = new Parse.Query(Parse.User);
    query.skip(skip)
    query.limit(limit)
    query.descending("createdAt")
    return await query.find({useMasterKey: true});
}

export async function getUserById(id) {
    const query = new Parse.Query(Parse.User);
    query.equalTo("objectId",id)
    query.include(["userInformation.userInformationBirthday"]);
    query.include(["userInformation.userInformationSex"]);
    query.include(["userInformation.userInformationCountry"]);
    query.include(["userInformation.userInformationCity"]);
    query.include(["userInformation.userInformationEmail"]);
    query.include(["userInformation.userInformationPhone"]);
    return await query.first({useMasterKey: true});
}